.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(0, 21, 41);
  }
  
  .ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-layout-background {
    background: #fff;
  }
  .ant-menu-submenu {
    padding: 0 4px;
  }

  .ant-layout-sider-zero-width-trigger{
    top: 2px;
  }